<template>
  <layout class="leaderboard" v-load="loading">
    <div class="row">
      <div class="col-md-12">
        <h2>Sent feedbacks</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <multiselect :multiple="false" v-model="filter.userTo" deselect-label="Remove" selected-label="Press" track-by="name" label="name" placeholder="Select to" :options="employeeList" :searchable="true">
                    <template slot="singleLabel" slot-scope="{ option }"> {{ option.name }} </template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <multiselect :multiple="false" v-model="filter.department" deselect-label="Remove" selected-label="Press" track-by="name" label="name" placeholder="Select department" :options="departments" :searchable="true">
                    <template slot="singleLabel" slot-scope="{ option }"> {{ option.name }} </template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <date-picker v-model="filter.date" placeholder="Select date" range></date-picker>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <div class="search-input">
                    <i class="bx bx-search font-size-20" />
                    <b-input-group>
                      <b-form-input
                          placeholder="Search by text..."
                          v-model="filter.search"
                      />
                    </b-input-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mb-3" v-if="showClearBtn">
              <button class="btn btn-primary" @click.prevent="clearFilter">Clear</button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body p-0">
            <div class="table" v-if="feedbacks.length > 0">
              <b-table hover :items="feedbacks" :fields="fields" stacked="md" id="account-table">
                <template #cell(#)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(created_at)="data">
                  <p> {{ $moment(data.item.created_at).format('LL') }} </p>
                </template>
              </b-table>
            </div>
            <div v-else>
              <b-table :fields="fields" id="account-table" hover />
              <EmptyPage/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <pagination
            :page="page"
            :limit="pageLimit"
            :totalRecordsCount="totalRows"
            @onPageChange="handlePageChange"
            @onLimitChange="handleLimitChange"
        />
      </div>
    </div>
  </layout>
</template>

<script>
import EmptyPage from '@/components/empty-page';
import Multiselect from 'vue-multiselect';
import DatePicker from "vue2-datepicker";
import Pagination from "@/components/pagination.vue";

export default {
  name: "weekly-page-sent",
  components: {
    Pagination,
    EmptyPage,
    Multiselect,
    DatePicker
  },
  data () {
    return {
      fields: [
        {
          key: '#',
          label: '#',
          thStyle: { width: '60px' },
          thClass: 'text-left',
          tdClass: 'text-left'
        },
        {
          key: 'recipient.name',
          label: 'To',
          thStyle: { width: '200px' }
        },
        {
          key: 'created_at',
          label: 'Date',
          thStyle: { width: '150px' }
        },
        {
          key: 'text',
          label: 'Text',
          thStyle: { minWidth: '200px' }
        },
      ],
      filter: {
        userTo: null,
        date: [],
        department: null,
        search: ''
      },
      pageLimit: 20,
      page: 1
    }
  },
  computed: {
    loading () {
      return this.$store.state.weeklyFeedback.loading;
    },
    employeeList () {
      return this.$store.getters['employees/employeeListForSelect']
    },
    queryParams () {
      return {
        to: this.filter.userTo?.uid ? this.filter.userTo.uid : null,
        st: this.filter.search.length > 3 ? this.filter.search : null,
        department: this.filter.department?.value ? this.filter.department.value : null,
        date_start: (this.filter.date.length > 1 && this.filter.date[0] !== null) ? this.$moment(this.filter.date[0]).format('YYYY-MM-DD') : null,
        date_end: (this.filter.date.length > 1 && this.filter.date[1] !== null) ? this.$moment(this.filter.date[1]).format('YYYY-MM-DD') : null,
        page: this.page,
        per_page: this.pageLimit,
      }
    },
    feedbacks () {
      if (this.$store.state.weeklyFeedback.myFeedbacks?.data && this.$store.state.weeklyFeedback.myFeedbacks?.data.length > 0) {
        const data = this.$store.state.weeklyFeedback.myFeedbacks.data.map(item => {
          const findColorRow = () => {
            if (item.type.id === 1) {
              return 'success'
            }
            if (item.type.id === 2) {
              return 'secondary'
            }
            if (item.type.id === 3) {
              return 'danger'
            }
          }
          return {
            recipient: {
              name: item.recipient.name
            },
            created_at: item.created_at,
            text: item.text,
            _rowVariant: findColorRow()
          }
        })
        return data
      }
      return []
    },
    totalRows () {
      return this.$store.state.weeklyFeedback.totalItems
    },
    showClearBtn () {
      return this.filter.userTo || this.filter.search.length > 3 || this.filter.department || (this.filter.date.length > 0 && this.filter.date.some(e => e !== null))
    },
    departments() {
      return this.$store.state.departments.list.map((department) => ({
        name: department.title, value: department.id
      })).sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  async mounted() {
    await this.$store.dispatch('employees/getAllEmployees')
    await this.$store.dispatch('departments/getDepartments');
    await this.constructFilters()
    await this.$store.dispatch('weeklyFeedback/getMyFeedbacks', this.queryParams)
  },
  methods: {
    handlePageChange(page) {
      this.page = page
    },
    handleLimitChange(limit) {
      this.pageLimit = limit
    },
    clearFilter () {
      for (const [key] of Object.entries(this.filter)) {
        if (key !== 'date' && key !== 'search') {
          this.filter[key] = null
        } else if (key === 'search') {
          this.filter[key] = ''
        }
        else {
          this.filter[key] = []
        }
      }
    },
    constructFilters () {
      if (this.$store.state.weeklyFeedback.filters.sentFeedbacks) {
        const data = {}

        for (const [key] of Object.entries(this.filter)) {
          if (key === 'date') {
            let dates = this.$store.state.weeklyFeedback.filters.sentFeedbacks[key]
            data[key] = (dates.length > 1 && dates.some(e => e !== null)) ? [new Date(dates[0]), new Date(dates[1])] : []
          } else {
            data[key] = this.$store.state.weeklyFeedback.filters.sentFeedbacks[key]
          }
        }
        this.filter = data
      }
    }
  },
  watch: {
    async pageLimit (val) {
      if (val) {
        await this.$store.dispatch('weeklyFeedback/getMyFeedbacks', this.queryParams)
      }
    },
    async page (val) {
      if (val) {
        await this.$store.dispatch('weeklyFeedback/getMyFeedbacks', this.queryParams)
      }
    },
    async 'filter.userTo' () {
      await this.$store.dispatch('weeklyFeedback/getMyFeedbacks', this.queryParams)
      await this.$store.commit('weeklyFeedback/SET_FILTER_FOR_SENT', this.filter)
    },
    async 'filter.search' (val) {
      if (val.length === 0) {
        await this.$store.dispatch('weeklyFeedback/getMyFeedbacks', this.queryParams)
        await this.$store.commit('weeklyFeedback/SET_FILTER_FOR_SENT', this.filter)
      }
      if (val.length > 3) {
        await this.$store.dispatch('weeklyFeedback/getMyFeedbacks', this.queryParams)
        await this.$store.commit('weeklyFeedback/SET_FILTER_FOR_SENT', this.filter)
      }
    },
    async 'filter.date' () {
      await this.$store.dispatch('weeklyFeedback/getMyFeedbacks', this.queryParams)
      await this.$store.commit('weeklyFeedback/SET_FILTER_FOR_SENT', this.filter)
    },
    async 'filter.department' () {
      await this.$store.dispatch('weeklyFeedback/getMyFeedbacks', this.queryParams)
      await this.$store.commit('weeklyFeedback/SET_FILTER_FOR_SENT', this.filter)
    },
  }
}
</script>

<style lang="scss" scoped>
.table {
  margin-bottom: 0;
}
</style>
